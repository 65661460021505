<div class="container" [class.error]="isError" *ngIf="open">
  <div class="message">
    <ng-content></ng-content>
  </div>
  <div class="close">
    <button
      type="button"
      aria-label="Close notice"
      mat-icon-button
      class="biosimulations-button text"
      (click)="close()">
      <mat-icon aria-label="Close icon">close</mat-icon>
    </button>
  </div>
</div>
