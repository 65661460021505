<biosimulations-text-page-section
  [heading]="heading"
  [icon]="icon"
  [iconRouterLink]="iconRouterLink"
  [iconHref]="iconHref"
  [iconClick]="iconClick"
  [highlight]="highlight"
  [compact]="compact"
  [closed]="closed"
  [first]="first"
  [last]="last">
  <ng-content id="content"></ng-content>
</biosimulations-text-page-section>
