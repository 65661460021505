<div class="menu-item">
  <button mat-menu-item [disabled]="disabled">
    <div class="icon-container">
      <a *ngIf="href; else noHref" [href]="href" rel="noopener">
        {{ heading }}
        <biosimulations-icon class="menu-icon" *ngIf="icon" [icon]="icon"></biosimulations-icon>
      </a>
      <ng-template #noHref>{{ heading }}</ng-template>
    </div>
  </button>
</div>
