<ng-container>
  <span
    *ngFor="let part of appNameParts; let iPart = index"
    [ngClass]="{
      accent: (appNameParts.length - iPart) % 2 === 0,
      primary: (appNameParts.length - iPart) % 2 === 1
    }"
    >{{ part }}</span
  >
</ng-container>
