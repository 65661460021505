<div class="hover-menu-parent-container">
  <div
    [matMenuTriggerFor]="menu"
    #trigger="matMenuTrigger"
    (mouseenter)="openMenu(trigger)"
    (click)="navigate(trigger)"
    (mouseleave)="closeMenu(trigger)">
    <ng-content select="#trigger" [target]="target"></ng-content>
  </div>
  <mat-menu #menu="matMenu" yPosition="below" [hasBackdrop]="false">
    <div (mouseenter)="openMenu(trigger)" (mouseleave)="closeMenu(trigger)">
      <ng-content select="#items" [target]="target"></ng-content>
    </div>
  </mat-menu>
</div>
