<ng-container *ngIf="showTable$ | async; else stackedTable">
  <biosimulations-table
    #table
    [columns]="columns"
    [highlightRow]="highlightRow"
    [sortable]="sortable"
    [defaultSort]="defaultSort"
    [controls]="controls"
    [data]="data"
    [dataContainer]="dataContainer">
    <div
      class="row"
      gdAuto="row"
      [gdColumns.gt-sm]="controls ? 'auto 1fr' : '1fr'"
      [gdGap]="table.controlsOpen ? '2rem' : 0"
      gdRows.lt-md="1fr"
      gdGap.lt-md="1rem">
      <biosimulations-table-controls
        *ngIf="controls"
        [table]="table"
        [searchPlaceHolder]="searchPlaceHolder"
        [searchToolTip]="searchToolTip"
        [ngClass]="{ closed: !table.controlsOpen }"></biosimulations-table-controls>

      <biosimulations-table-tabular-data
        #dataContainer
        [table]="table"
        [singleLineHeadings]="singleLineHeadings"
        [sortable]="sortable"
        [linesPerRow]="linesPerRow"
        [ngClass]="{ 'square-left-border': !table.controlsOpen }"></biosimulations-table-tabular-data>
    </div>
  </biosimulations-table>
</ng-container>

<ng-template #stackedTable>
  <biosimulations-stacked-table
    [contentsHeading]="stackedContentsHeading"
    [columns]="columns"
    [getHeading]="getStackedHeading"
    [getHeadingMoreInfoRouterLink]="getStackedHeadingMoreInfoRouterLink"
    [getHeadingMoreInfoHref]="getStackedHeadingMoreInfoHref"
    [highlightRow]="highlightRow"
    [defaultSort]="defaultSort"
    [data]="data">
  </biosimulations-stacked-table>
</ng-template>
