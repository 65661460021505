<div class="container">
  <div class="heading"><ng-content select="#heading"></ng-content></div>
  <div
    class="subsections"
    gdAuto="row"
    gdGap="24px"
    [gdColumns]="'repeat(' + columns.toString() + ', 1fr)'"
    gdColumns.lt-md="1fr">
    <ng-content select="#subsection"></ng-content>
  </div>
</div>
