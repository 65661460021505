<div class="container">
  <div class="title" (click)="table.toggleControls()" [ngClass]="{ hidden: !closeable }">
    <div class="title-inner">Controls</div>
    <biosimulations-icon icon="controls"></biosimulations-icon>
  </div>

  <mat-accordion class="controls" [ngClass]="{ 'left-square-border': !closeable }">
    <!-- Columns panel -->
    <mat-expansion-panel
      [expanded]="table.openControlPanelId === 0"
      [disabled]="table.openControlPanelId === 0"
      (opened)="table.openControlPanel(1)">
      <mat-expansion-panel-header collapsedHeight="32px" expandedHeight="32px">
        <mat-panel-title>
          <biosimulations-icon icon="columns"></biosimulations-icon>
          {{ attributesHeading }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-list>
          <ng-container *ngFor="let column of table.columns">
            <mat-list-item *ngIf="column?.hidden !== true">
              <mat-checkbox [checked]="table.showColumns[column.id]" (change)="table.toggleColumn(column)">
                {{ column.heading }}
              </mat-checkbox>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </ng-template>
    </mat-expansion-panel>

    <!-- Filters panel -->
    <mat-expansion-panel
      [expanded]="table.openControlPanelId === 2"
      [disabled]="table.openControlPanelId === 0"
      (opened)="table.openControlPanel(2)">
      <mat-expansion-panel-header collapsedHeight="32px" expandedHeight="32px">
        <mat-panel-title>
          <biosimulations-icon icon="filter"></biosimulations-icon>
          Filters
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-accordion class="controls">
          <ng-template ngFor let-column [ngForOf]="table.columns">
            <mat-expansion-panel *ngIf="column.filterable !== false">
              <mat-expansion-panel-header collapsedHeight="32px" expandedHeight="32px">
                <mat-panel-title>
                  {{ column.heading }}{{ column?.units ? ' (' + column.units + ')' : '' }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <ng-container [ngSwitch]="column.filterType">
                  <ng-template [ngSwitchCase]="'number'">
                    <ng-template
                      let-range="range"
                      [ngTemplateOutletContext]="{
                        range: table.columnFilterData[column.id]
                      }"
                      [ngTemplateOutlet]="self"
                      #self>
                      <biosimulations-slider
                        [min]="range.min"
                        [max]="range.max"
                        [step]="range.step"
                        [minSelected]="range.minSelected"
                        [maxSelected]="range.maxSelected"
                        (onChange)="table.filterNumberValue(column, range, $event)">
                      </biosimulations-slider>

                      <button
                        mat-icon-button
                        class="biosimulations-button text filter-set-clear-all"
                        (click)="table.clearFilter(column)">
                        <span class="btn-contents">Clear filter</span>
                      </button>
                    </ng-template>
                  </ng-template>

                  <ng-container *ngSwitchCase="'date'">
                    <mat-form-field appearance="fill">
                      <mat-label>Enter a range of dates</mat-label>
                      <mat-date-range-input [rangePicker]="picker">
                        <input
                          matStartDate
                          placeholder="Start date"
                          (dateChange)="table.filterStartDateValue(column, $event)"
                          [value]="table.columnFilterData[column.id][0]" />
                        <input
                          matEndDate
                          placeholder="End date"
                          (dateChange)="table.filterEndDateValue(column, $event)"
                          [value]="table.columnFilterData[column.id][1]" />
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>

                    <button
                      mat-icon-button
                      class="biosimulations-button text filter-set-clear-all"
                      (click)="table.clearFilter(column)">
                      <span class="btn-contents">Clear filter</span>
                    </button>
                  </ng-container>

                  <ng-container *ngSwitchCase="'stringAutoComplete'">
                    <mat-chip-grid #chipList>
                      <ng-container *ngFor="let value of table.columnFilterData[column.id]">
                        <ng-container *ngIf="value.checked">
                          <mat-chip-row [removable]="true" (removed)="table.filterSetValue(column, value, false)">
                            <div class="chip-content">
                              {{ value.formattedValue }}
                            </div>
                            <button matChipRemove>
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </mat-chip-row>
                        </ng-container>
                      </ng-container>
                      <mat-form-field appearance="fill" class="input-field">
                        <input
                          matInput
                          type="text"
                          #autoCompleteInput
                          placeholder="Enter {{ column.heading | lowercase }} ..."
                          [matAutocomplete]="auto"
                          [matChipInputFor]="chipList"
                          (keyup)="table.evalAutocompleteFilter(column, autoCompleteInput.value)" />
                      </mat-form-field>
                    </mat-chip-grid>
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      (optionSelected)="
                        table.filterSetValue(column, $event.option.value, true); autoCompleteInput.value = ''
                      ">
                      <ng-container *ngFor="let value of table.columnFilterData[column.id]">
                        <mat-option *ngIf="value?.filtered !== false" [value]="value">
                          {{ value.formattedValue }}
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>

                    <button
                      mat-icon-button
                      class="biosimulations-button text filter-set-clear-all"
                      (click)="table.clearFilter(column)">
                      <span class="btn-contents">Clear filter</span>
                    </button>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <mat-list>
                      <mat-list-item *ngFor="let value of table.columnFilterData[column.id]">
                        <mat-checkbox
                          #checkbox
                          [checked]="value.checked"
                          (change)="table.filterSetValue(column, value, checkbox.checked)"
                          ng-attr-title
                          [attr.title]="column.showFilterItemToolTips === true ? value.formattedValue : null">
                          {{ value.formattedValue }}
                        </mat-checkbox>
                      </mat-list-item>
                    </mat-list>
                    <button
                      mat-icon-button
                      class="biosimulations-button text filter-set-clear-all"
                      (click)="table.clearFilter(column)">
                      <span class="btn-contents">Clear filter</span>
                    </button>
                  </ng-container>
                </ng-container>
              </ng-template>
            </mat-expansion-panel>
          </ng-template>
        </mat-accordion>
      </ng-template>
    </mat-expansion-panel>

    <!-- Search panel -->
    <mat-expansion-panel
      [expanded]="table.openControlPanelId === 3"
      [disabled]="table.openControlPanelId === 0"
      (opened)="table.openControlPanel(3)">
      <mat-expansion-panel-header collapsedHeight="32px" expandedHeight="32px">
        <mat-panel-title>
          <biosimulations-icon icon="search"></biosimulations-icon>
          Search
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-form-field appearance="fill" class="input-field">
          <input
            #searchInput
            matInput
            type="text"
            [value]="table.searchQuery || null"
            (keydown.enter)="table.searchRows(searchInput.value)"
            [placeholder]="searchPlaceHolder"
            [matTooltip]="searchToolTip" />
          <button
            matSuffix
            mat-icon-button
            class="biosimulations-button text"
            (click)="table.searchRows(searchInput.value)">
            <biosimulations-icon icon="search"></biosimulations-icon>
          </button>
        </mat-form-field>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
