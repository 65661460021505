<biosimulations-navigation
  *ngIf="!isMobileSimulators; else mobileSimulators"
  [appName]="config.appName"
  [appNameParts]="config.appNameParts"
  [logo]="config.logo"
  [privacyPolicyVersion]="config.privacyPolicyVersion"
  [newsVersion]="1"
  [showNews]="false"
  [healthy]="healthy$ | async">
  <biosimulations-topbar-menu>
    <biosimulations-hover-open-menu
      [disabled]="true"
      [routerLink]="['/simulators']"
      matTooltip="Browse simulator tools">
      <biosimulations-topbar-menu-item
        [appName]="config.appName"
        heading="BioSimulators"
        icon="simulator"
        id="trigger"
        target="_self">
      </biosimulations-topbar-menu-item>
    </biosimulations-hover-open-menu>

    <biosimulations-hover-open-menu [href]="appRoutes.dispatchApp" matTooltip="Run a simulation">
      <biosimulations-topbar-menu-item
        [appName]="config.appName"
        heading="Run Simulations"
        icon="experiment"
        id="trigger"
        target="_self">
      </biosimulations-topbar-menu-item>
      <ng-container id="items">
        <biosimulations-dropdown-menu-item [href]="browseRunsUrl" heading="Browse Simulation Runs">
        </biosimulations-dropdown-menu-item>
        <biosimulations-dropdown-menu-item [href]="runUrl" heading="Run A Simulation">
        </biosimulations-dropdown-menu-item>
      </ng-container>
    </biosimulations-hover-open-menu>

    <biosimulations-hero-banner-util-button [appName]="config.appName"></biosimulations-hero-banner-util-button>

    <biosimulations-hover-open-menu
      [disabled]="true"
      [href]="appRoutes.platformApp"
      target="_blank"
      matTooltip="Browse simulation projects">
      <biosimulations-topbar-menu-item [appName]="config.appName" heading="BioSimulations" icon="link" id="trigger">
      </biosimulations-topbar-menu-item>
    </biosimulations-hover-open-menu>
  </biosimulations-topbar-menu>

  <ng-container id="news"></ng-container>
  <router-outlet></router-outlet>
</biosimulations-navigation>
<biosimulations-app-footer></biosimulations-app-footer>

<ng-template #mobileSimulators>
  <biosimulations-navigation
    [appName]="config.appName"
    [appNameParts]="config.appNameParts"
    [logo]="config.logo"
    [privacyPolicyVersion]="config.privacyPolicyVersion"
    [newsVersion]="1"
    [showNews]="false"
    [healthy]="healthy$ | async">
    <biosimulations-topbar-menu></biosimulations-topbar-menu>
    <ng-container id="news"> Under Construction </ng-container>
    <router-outlet></router-outlet>
  </biosimulations-navigation>
</ng-template>
