<biosimulations-error icon="bug" [code]="code" [pageHasBreadCrumbs]="pageHasBreadCrumbs">
  <ng-container id="message">{{ message }}</ng-container>
  <ng-container id="details">
    We're sorry! {{ details }} Please try again later. If this error persists, please submit an
    <span class="no-wrap"
      >issue
      <a [href]="newIssueUrl" rel="noopener" target="githubissues"
        ><biosimulations-icon icon="link"></biosimulations-icon></a
    ></span>
    or contact us at
    <a [href]="emailUrl" subject="BioSimulations error">{{ email }}</a
    >.
  </ng-container>
</biosimulations-error>
