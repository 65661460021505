<biosimulations-page [heading]="_heading" class="page-container">
  <div gdColumns.gt-sm="16rem 1fr" gdGap.gt-sm="2rem">
    <div class="side-bar">
      <div class="sections-container" [ngStyle]="sideBarStyle$ | async">
        <biosimulations-text-page-side-bar-section
          *ngIf="tocSections | async as sections"
          [heading]="contentsHeading"
          [fxHide]="!contentsHeading">
          <ul>
            <ng-container *ngFor="let section of sections">
              <biosimulations-text-page-toc-item
                *ngIf="section.heading | async as heading"
                [heading]="heading"
                [scrollTarget]="section.target"
                [scrollOffset]="tocScrollSectionScrollOffset">
              </biosimulations-text-page-toc-item>
            </ng-container>
          </ul>
        </biosimulations-text-page-side-bar-section>
        <ng-content select="#sideBar"></ng-content>
      </div>
    </div>

    <div #sectionsContainer class="sections-container content-container" [ngClass]="{ compact: compact }">
      <ng-content select="#content"></ng-content>
    </div>
  </div>
</biosimulations-page>
