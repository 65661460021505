<mat-sidenav-container
  class="sidenav-container mat-display-3 mat-app-background"
  [ngClass]="{ 'theme-alternate': darkMode }">
  <!-- Hero Banner for Normal Size -->
  <biosimulations-topbar>
    <biosimulations-logo-text id="logo" [appNameParts]="appNameParts"></biosimulations-logo-text>
    <button
      id="rightContent"
      class="biosimulations-button white sidenav-topbar-right-button"
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()"
      *ngIf="drawer.opened">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
  </biosimulations-topbar>

  <!-- Hamburger for Smaller Screens-->
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'">
    <div class="sidenav-menu-container">
      <ng-content select="#sidenav-menu"></ng-content>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <div class="topbar">
      <biosimulations-topbar
        [showLogo]="!drawer.opened"
        [color]="appName === 'BioSimulators' ? simulatorsColor : simulationsColor">
        <biosimulations-logo-image class="logo-img" id="logo" [logo]="logo"> </biosimulations-logo-image>
        <ng-content id="rightContent" select="biosimulations-topbar-menu"> </ng-content>
      </biosimulations-topbar>
    </div>
    <div class="content">
      <ng-content select="router-outlet"></ng-content>
    </div>
    <biosimulations-notice *ngIf="showNews" [appName]="appName" type="news" [version]="newsVersion" [isError]="false">
      <ng-content select="#news"></ng-content>
    </biosimulations-notice>
    <biosimulations-notice *ngIf="healthy === false" [appName]="appName" type="health" [isError]="true">
      Sorry! {{ appName }} is currently down. We are working to restore service. Please check back soon. Additional
      status information is available at
      <a href="https://status.biosimulations.org" rel="noopener" target="status">https://status.biosimulations.org</a>.
    </biosimulations-notice>
  </mat-sidenav-content>
</mat-sidenav-container>
