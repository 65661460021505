<ng-container *ngIf="appName === 'BioSimulators'; else simulationsBtns">
  <!-- Linking button -->
  <div class="overlap simulators">
    <a
      mat-flat-button
      *ngIf="href && !disabled; else button"
      [href]="href"
      rel="noopener"
      [target]="target"
      style="background-color: #2196f3; color: white; font-weight: 700">
      <div class="button-content">
        <biosimulations-icon [icon]="icon"></biosimulations-icon>
        {{ heading }}
      </div>
    </a>
  </div>

  <!-- Dropdown Button -->
  <ng-template #button>
    <button
      mat-flat-button
      [routerLink]="route || null"
      [queryParams]="queryParams"
      [disabled]="disabled"
      style="background-color: #2196f3; color: white; font-weight: 700">
      <div class="button-content">
        <biosimulations-icon [icon]="icon"></biosimulations-icon>
        {{ heading }}
      </div>
    </button>
  </ng-template>
</ng-container>

<ng-template #simulationsBtns>
  <div class="overlap simulations">
    <a
      mat-flat-button
      *ngIf="href && !disabled; else button"
      [href]="href"
      rel="noopener"
      [target]="target"
      style="background-color: #ffa600; color: #1b4b0b; font-weight: 700">
      <div class="button-content">
        <biosimulations-icon [icon]="icon"></biosimulations-icon>
        {{ heading }}
      </div>
    </a>
  </div>
  <ng-template #button>
    <button
      mat-flat-button
      [routerLink]="route || null"
      [queryParams]="queryParams"
      [disabled]="disabled"
      style="background-color: #ffa600; color: #1b4b0b; font-weight: 700">
      <div class="button-content">
        <biosimulations-icon [icon]="icon"></biosimulations-icon>
        {{ heading }}
      </div>
    </button>
  </ng-template>
</ng-template>
