<div class="container">
  <biosimulations-icon *ngIf="icon" [icon]="icon" [style.color]="iconColor"></biosimulations-icon>
  <div class="heading">
    <a *ngIf="hrefHeader; else normalHeader" [href]="link">{{ heading }}</a>
    <ng-template #normalHeader>
      {{ heading }}
    </ng-template>
  </div>
  <div
    [ngClass]="{
      'subheading-small': subheadingSize === 'small',
      'subheading-large': subheadingSize === 'large'
    }">
    {{ subheading }}
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
