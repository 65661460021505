<div class="app-footer-container">
  <biosimulations-home-section [columns]="3" class="home-footer">
    <biosimulations-home-subsection
      *ngFor="let footer of homeSubsectionItems"
      [heading]="footer.heading"
      [icon]="footer.icon"
      [style]="footer.style"
      id="subsection"
      subheading=""
      subheadingSize="small">
      <mat-list *ngFor="let item of footer.contentItems">
        <a [href]="item.href" target="biosimulations-docs">
          <button mat-button [class]="item.cssClass">
            {{ item.title }}
          </button>
        </a>
      </mat-list>
    </biosimulations-home-subsection>
  </biosimulations-home-section>
</div>
