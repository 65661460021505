<div
  class="text-page-section-parent-container"
  ngLayout="row"
  [ngClass]="{
    'highlight-container': highlight,
    compact: compact,
    first: first,
    last: last,
    closed: closed
  }">
  <h3>
    <div class="heading">{{ heading }}</div>
    <ng-container *ngIf="icon">
      <a
        *ngIf="iconRouterLink; else noRouterLink"
        mat-icon-button
        class="icon-container biosimulations-button"
        [routerLink]="iconRouterLink">
        <biosimulations-icon [icon]="icon"></biosimulations-icon>
      </a>

      <ng-template #noRouterLink>
        <a
          *ngIf="iconHref; else noRouterHref"
          mat-icon-button
          class="icon-container biosimulations-button"
          [href]="iconHref"
          rel="noopener"
          [target]="iconTarget">
          <biosimulations-icon [icon]="icon"></biosimulations-icon>
        </a>

        <ng-template #noRouterHref>
          <a *ngIf="iconClick" mat-icon-button class="icon-container biosimulations-button" (click)="iconClick()">
            <biosimulations-icon [icon]="icon"></biosimulations-icon>
          </a>
        </ng-template>
      </ng-template>
    </ng-container>
    <div class="clear-float"></div>
  </h3>
  <div class="content">
    <ng-content select="#content"></ng-content>
  </div>
</div>
