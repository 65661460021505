<div class="text-page-sidebar-parent-container">
  <mat-card class="sidebar-card">
    <mat-card-header class="multipurpose-card-header sidebar">
      {{ heading }}
    </mat-card-header>
    <mat-card-content>
      <ng-content id="content"></ng-content>
    </mat-card-content>
  </mat-card>
</div>
